/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

$colors: (
  standard-white: #ffffff,
  standard-black: #000000,
  dark-blue: #1c1c29,
  ketos-blue: #0e8ed9,
  ketos-darkblue: #0a1219,
  standard-grey: #cccccc,
  standard-red: #ff0000,
  ketos-black: #0a0a0f,0
  ketos-light-gray: #848484,
  ketos-medium-gray: #1b1b29,
  ketos-brown: #8b3a0d,
  ketos-orange: #ff5500,
  ketos-turquoise: #21f6dc,
  ketos-dimgray: #6d6e71,
  ketos-midgray: #9b9b9b,
  ketos-khaki: #e9e96d,
  ketos-chartreuse: #7fff00,
  ketos-darkslategray: #22212d,
  ketos-purple: #232334,
  ketos-dark-purple: #242434,
  ketos-green: #009688
);
