/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

$hourglass-primary: #1b1b29;
$hourglass-secondary: #7fff00;
$hourglass-bgd: #1b1b29;

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-color: $hourglass-primary;
  opacity: 0.7;
.loader .gif{
 position: absolute;
    top: 40%;
    left: 48%;
    height: 100px;
    width: 100px;
    background-image:url(../assets/preloader/images/loading-orca.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
  .loader svg {
    position: absolute;
    top: 30%;
    left: 50%;
    height: 70px;
    width: 70px;
    & path, & rect {
      fill: #0e8ed9;
    }
  }
}
