/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

@import 'patterns';

$map: (
  color: map-get($colors, standard-white),
  color-second: map-get($colors, ketos-dimgray),
  bgd: map-get($colors, ketos-medium-gray),
  svgColor: map-get($colors, ketos-blue)
);
$map-header: (
  bgd: map-get($colors, ketos-darkblue)
);
