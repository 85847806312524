/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

.mat-dialog-container {
  background-repeat: no-repeat;
  background-image: linear-gradient(239deg, #242434, #151d20);
  padding: 20px;
  color: #ffffff;
}

.mat-dialog-title {

}

.mat-dialog-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 0;
  min-width: 330px;
  margin: 10px 0 19px 0;
  text-align: center;
  line-height: 1.64;
  font-family: RobotoMedium, sans-serif;
  font-size: 16px;
  @include custom-scrollbar();
}

.mat-dialog-actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .mat-raised-button {
    font-size: 12px;
    font-weight: bold;
    outline: none;
    padding: 4px;
    cursor: pointer;
    margin-left: 11px;
    background-color: transparent;
    border: none;
    //popup__buttonsWrap button.ng-disabled
    &.ng-disabled {
      cursor: not-allowed;
    }
  }
  .mat-primary {
    font-size: 14px;
    border: 1px solid #00b2ff;
    border-radius: 5px;
    padding: 10px 15px;
    width: 60px;
    color: #fff;
  }
}

.popup-cancelButton {
  color: #9b9b9b;
}
//popup__buttonsWrap__okButton
.popup-okButton {
  color: #0e8ed9;
}






.popup-popup {
  background-repeat: no-repeat;
  background-image: linear-gradient(239deg, #242434, #151d20);
  border-radius: 5px;

  // For usage billing
  .popup-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .popup-col-wrap {
      display: flex;
      flex: 0 0 calc(50% - 13px);
      align-items: center;
      justify-content: flex-start;
      //flex-flow: column nowrap;
      //align-content: flex-start;
      //margin: 0 0 16px 0;

      label {
        flex: 0 0 auto;
      }

      input {
        flex: 1;
      }

      input[type=checkbox] {
        flex: 1;
        margin-top: 16px;
        vertical-align: bottom;
        background-color: blue;
      }
    }
  }

  padding: 20px;
  color: #ffffff;
  .popup-close {
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    width: 14px;
    height: 14px;
    background: {
      size: cover;
      color: transparent;
      image: url(/assets/shared/images/close.svg);
      repeat: no-repeat;
    };
  }
  //@include state(mapWrap) {
  //  min-height: 380px;
  //  width: 615px;
  //  padding: 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  color: #ffffff;
  //  font-size: 20px;
  //  font-family: RobotoLight, sans-serif;
  //}
  //popup__confirm-text
  .popup-confirm-text {
    font-size: 16px;
    font-weight: 300;
    font-family: RobotoMedium, sans-serif;
    text-align: center;
    margin-bottom: 15px;
  }
  ////popup__title
  //@include element(title) {
  //  font-size: 24px;
  //  font-weight: 200;
  //  font-family: RobotoMedium, sans-serif;
  //  text-align: center;
  //  margin-bottom: 15px;
  //}
  //popup__message
  .popup-confirm-message {
    padding: 15px 0;
    min-width: 330px;
    margin: 10px 0 19px 0;
    text-align: center;
    line-height: 1.64;
    font-family: RobotoMedium, sans-serif;
    font-size: 16px;
    @include custom-scrollbar();
  }
  ////popup__message
  //@include element(message) {
  //  padding: 15px 0;
  //  min-width: 330px;
  //  margin: 10px 0 19px 0;
  //  text-align: center;
  //  line-height: 1.64;
  //  font-family: RobotoMedium, sans-serif;
  //  font-size: 13px;
  //}
  //////popup__buttonsWrap3
  //@include element(buttonsWrap3) {
  //  display: flex;
  //  margin-top: 7px;
  //  button {
  //    font-size: 12px;
  //    color: #00b2ff;
  //    outline: none;
  //    cursor: pointer;
  //    margin-bottom:0px;
  //    background-color: transparent;
  //    //popup__buttonsWrap2 button.ng-disabled
  //    &.ng-disabled {
  //      cursor: not-allowed;
  //    }
  //  }
  //  //popup__buttonsWrap3__confirmButton
  //  @include element(confirmButton) {
  //    border: 0px solid #00b2ff;
  //    right: 76%;
  //    position: relative;
  //  }
  //  //popup__buttonsWrap3__okButton
  //  @include element(cancelButton) {
  //    border: 0px solid #c6c6c6;
  //  }
  //}
  ////popup__buttonsWrap2
  .popup-buttonsWrap2 {
    display: flex;
    button {
      font-size: inherit;
      color: inherit;
      outline: none;
      padding: 12px 12px;
      cursor: pointer;
      background-color: transparent;
      border-radius: 5px;
      //popup__buttonsWrap2 button.ng-disabled
      &.ng-disabled {
        cursor: not-allowed;
      }
    }
    //popup__buttonsWrap2__okButton
    .okButton {
      border: 1px solid #00b2ff;
      margin-right: 20px;
    }
    //popup__buttonsWrap2__okButton
    .cancelButton {
      border: 1px solid #00b2ff;
    }
  }
  //popup__buttonsWrap
  .popup-buttonsWrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    button {
      font-size: 12px;
      font-weight: bold;
      outline: none;
      padding: 4px;
      cursor: pointer;
      margin-left: 11px;
      background-color: transparent;
      border: none;
      //popup__buttonsWrap button.ng-disabled
      &.ng-disabled {
        cursor: not-allowed;
      }
    }
    button.popup-confirm-button {
      font-size: 14px;
      border: 1px solid #00b2ff;
      border-radius: 5px;
      padding: 10px 15px;
      width: 60px;
      color: #fff;
    }
    //popup__buttonsWrap__cancelButton
    .popup-cancelButton {
      color: #9b9b9b;
    }
    //popup__buttonsWrap__okButton
    .popup-okButton {
      color: #0e8ed9;
    }
  }
  ////popup +appForm
  //@include state(appForm) {
  //  min-width: 480px;
  //}
  ////popup +userForm
  //@include state(userForm) {
  //  min-width: 390px;
  //}
}


.confirmButton {
  border: 0px solid #00b2ff;
  right: 76%;
  position: relative;
}

.cancelButton {
  border: 0px solid #c6c6c6;
}

//popup__buttonsWrap3
.buttonsWrap3 {
  display: flex;
  margin-top: 7px;
  button {
    font-size: 12px;
    color: #00b2ff;
    outline: none;
    cursor: pointer;
    margin-bottom:0px;
    background-color: transparent;
    //popup__buttonsWrap2 button.ng-disabled
    &.ng-disabled {
      cursor: not-allowed;
    }
  }
  ////popup__buttonsWrap3__confirmButton
  //.confirmButton {
  //  border: 0px solid #00b2ff;
  //  right: 76%;
  //  position: relative;
  //}
  ////popup__buttonsWrap3__okButton
  //.cancelButton {
  //  border: 0px solid #c6c6c6;
  //}
}

.buttonsWrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  button {
    font-size: 12px;
    font-weight: bold;
    outline: none;
    padding: 4px;
    cursor: pointer;
    margin-left: 11px;
    background-color: transparent;
    border: none;
    //popup__buttonsWrap button.ng-disabled
    &.ng-disabled {
      cursor: not-allowed;
    }
  }
  button.confirm-button {
    font-size: 14px;
    border: 1px solid #00b2ff;
    border-radius: 5px;
    padding: 10px 15px;
    width: 60px;
    color: #fff;
  }
  ////popup__buttonsWrap__cancelButton
  //.cancelButton {
  //  color: #9b9b9b;
  //}
  ////popup__buttonsWrap__okButton
  //.okButton {
  //  color: #0e8ed9;
  //}
}

.dashboard-popup {
  position: relative;
  min-width: 600px;
  font-size: 16px;
  font-family: $font-light;
  input, textarea {
    font-family: inherit;
    font-size: 15px;
    width: 100%;
    color: #ffffff;
  }
  .textarea {
    padding: 11px 13px;
    font-size: 16px;
    background: none;
    margin-top: 25px;
    border: 2px solid #00b2ff;
    height: 114px;
    max-height: 114px;
    max-width: 660px;
    border-radius: 5px;
    @include placeholder(#ffffff, left)
  }
  .label {
    margin-top: 15px;
  }
  .title {
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
  }

  .input-title {
    text-align: left;
  }

  .buttonsWrap {
    margin-top: 28px;
    display: flex;
    justify-content: flex-end;
  }
}

.schedule_popup {
  min-width: 500px;
  span {
    margin-top: 4px;
    margin-left: 12px;
  }
}


