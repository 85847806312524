@import "./_mixins";
@import "./colors";
//@import "./patterns";
div.main.maximize {
  padding: 3%;
  overflow-y: auto;
  height: auto;
  z-index: 26;
  overflow-x: hidden;

  .multi-graphview {
    //width: 103% !important;
  }
}

.right-column div.main {
  border-radius: 5px;
}


.secondaryPages {
  div.main {
    min-height: 100%;
    border-radius: 5px;
    padding: 20px 38px 38px !important;
    color: #ffffff;
  }
  .inner-container{
    padding: 15px !important;
  }
}

.ketosWaveBlue {
  color: #00b2ff;
}

.dropdown {
  border-radius: 5px;
}

.ketosWaveDarkBlue {
  color: #1c1c29;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-sensors-info.minimize {
  height: auto !important;

  .info-wrap {
    padding: 0;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  div.main.maximize {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);
  }

}

::ng-deep highcharts-chart {
  border-radius: 5px !important;
}

::ng-deep .mat-tooltip {
  background: #00b2ff;
  color: #fff;
  box-shadow: 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  width: 50px;
  height: auto;
  width: fit-content;
  height: 40px;
  overflow: visible !important; /* NEW */
  font-size: 0.75rem;
  border: 1px solid #00b2ff;
}

::ng-deep .customTooltip{
  font-size: 14px !important;
  max-height: 30px !important;
  margin-left: 23px !important;
}

::ng-deep .mat-tooltip-panel-right {
  .mat-tooltip.customTooltip {
    &::before {
      position: absolute;
      top: 50%;
      padding: 0.5em;
      margin-left: 2%;
    }

    &:after {
      content: " ";
      position: absolute;
      left: -7px; /* previous value: bottom:-10px; */
      right: 100%;
      z-index: 999;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent #00b2ff transparent transparent;
    }
  }
}

::ng-deep .mat-tooltip-panel-below {
  .mat-tooltip {
    overflow: visible;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      border-style: solid;
      border-color: transparent transparent #00b2ff transparent;
      border-width: 12px;
      left: calc(50% - 12px);
    }
  }
}

::ng-deep .tooltip-inner {

  background-color: #00b2ff !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

::ng-deep .bs-tooltip-right {

  .arrow {

    &::before {
      right: 0;
      border-right-color: #00b2ff;
    }
  }
}

::ng-deep .logo {
  background-color: #232334;

  .mat-list-item {
    .mat-list-item-content {
      width: 100%;
      padding: 0;

      .title {
        font-weight: bold;
        display: inline-block;
        margin-left: auto !important;
        margin-right: auto !important;
        height: 33px !important;
      }
    }
  }
}

::ng-deep .agm-map-container-inner {
  border-radius: 0 !important;
}

::ng-deep {
  #map-container.google-maps {
    .map-container {
      width: 100% !important;
    }
    .gm-style-iw-chr {
      position: absolute;
      top: 0;
      right: 0;
    }
    .gm-style-iw-chr button {
      height: 16px !important;
      width: 16px !important;
      span {
        width: 14px !important;
        height: 14px !important;
        margin-left: auto !important;
        margin: 0 !important;
      }
    }

    .gm-style-iw-d {
      padding: 12px 0 0 0;
    }
  }
}

@media screen and (min-width: 720px) and (max-width: 1366px) {
  .left-column {
    flex: 0 0 55px !important;
    width: 55px !important;
  }
  .menu .submenu .menu-item {
    padding: 0 !important;

    a {
      margin: 0 !important;
    }
  }
  .chord {
    .icon-wrap {
      .icon {
        width: 50px;
        height: 50px;
      }
    }

    .info-wrap {
      margin: 0 0 0 10px !important;

      .title {
        font-size: 12px !important;
      }

      .value {
        font-size: 14px !important;
      }

      .ion-label {
        font-size: 10px !important;
      }
    }

    ion-button {
      font-size: 10px !important;
    }

  }
  .location-groups .main {
    padding: 25px 38px 45px 38px;
  }
  .list-of-sensors .main {
    padding: 25px 38px 45px 38px;
  }
  .manageUsers.main {
    padding: 20px 38px 38px;
  }
  .supportPage .main {
    padding: 25px 38px 45px 38px;
  }
  .profile .main {
    padding: 25px 38px 45px 38px;
  }
  .chord.chordDataSettings {
    .limits-wrap-settings {
     // width: 55% !important;

      ion-label {
        font-size: 10px !important;
      }
    }
  }

  .list-of-sensors {
    .main > div.header {
      margin-bottom: 6px !important;
    }
  }
  .location-groups {
    .main > div.header {
      margin-bottom: 6px !important;
    }
  }

  .secondaryPages {
    div.main {
      .main-header {
        margin-bottom: 12px !important;
      }
    }
  }
}
#sideMenu {
  transition: width 0.3s ease, margin-left 0.3s ease;
}

#rightColumn {
  transition: margin-left 0.3s ease;
}

#logo {
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
}

.secondaryPages{
  #logo{
    padding-left: 30px !important;
    margin: 0 !important;
  }
}

.schedule-tooltip {
  background-color: #00b2ff;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 250px;
  font-size: 1rem;
}

.schedule-tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #00b2ff transparent;
}

.secondaryPages {
  .right-column{
    padding: 0 !important;
  }
}

::ng-deep #openAssignLocationGroup .modal.modal-background .modal-body {
  background: transparent !important;
}

::ng-deep #confirmAssignLocationGroup .modal.modal-background .modal-body {
  background: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep input:focus, textarea:focus, select:focus, input.form-input.ng-pristine.ng-valid.ng-touched {
  outline: none !important;
}

.minTableHeight {
  @include custom-scrollbar();

  table th {
    position: sticky;
    top: 0;
    padding: 5px;
    height: 45px;
    background: #232334;
    z-index: 22;

    &:first-child {
      border-radius: 0 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

ion-button.greenBackground {
  background: #009688 !important;
}

.secondaryPages {
  overflow: hidden;

  div.main {
    .main-header {
      font-size: 30px !important;
    }
  }

  div.wrap {
    overflow: unset;
  }
}
div#rightColumn {
  overflow: unset;
  margin-left: 0 !important;
  padding-left: 15px;

  div.main {
    padding: 20px 38px 38px !important;
    height: calc(100% - 105px);
    min-height: 80%;
  }
}
.secondaryPages#mainContent {
  overflow: hidden;
  div.main {
    padding: 20px 38px 38px !important;
    min-height: unset;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

::ng-deep .logo.mobile {
  background-color: #1c1c29;
}
::ng-deep .si-content-wrapper {
  border-radius: 5px !important;
  padding: 25px;
}

::ng-deep .waterSaftetyInfoWindow .si-close-button{
  right: 5px;
  color: #000000;
}
ion-item.item-interactive.ion-valid {
  --highlight-background: transparent !important;
}

ion-input {
  caret-color: #ffffff;
}