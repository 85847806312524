/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

@import './mixins';

@include font-face('RobotoThin', '/assets/fonts/Roboto-Thin', woff);
@include font-face('RobotoLight', '/assets/fonts/Roboto-Light', woff);
@include font-face('RobotoRegular', '/assets/fonts/Roboto-Regular', woff);
@include font-face('RobotoMedium', '/assets/fonts/Roboto-Medium', woff);

//font types
$font-thin: 'RobotoThin';
$font-light: 'RobotoLight';
$font-regular: 'RobotoRegular';
$font-medium: 'RobotoMedium';
