/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

$sizes: (
  font-primary: 16px,
  font-title: 14px,
  font-weight-regular: 400,
  font-weight-medium: 500,
  font-weight-bold: 700,

  btn-line-height: 50px,
  btn-padding: 0 60px,
  btn-font-size: 16px,

  input-font-size: 16px,
  input-line-height: 56px,

  anchor-font-size: 12px
);
