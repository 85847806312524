@import "src/lib/scss/mixins";
@import "src/lib/scss/colors";
@import "src/lib//scss/fonts";
@import "src/lib/scss/preloader";

@keyframes spin {
  0% { transform:rotate(0deg); }
  93% { transform:rotate(0deg); }
  100% { transform:rotate(180deg); }
}

:root {
  --global-background-color: #000000;
  --global-text-color: #ffffff;
  --global-background-gradient: linear-gradient(239deg, #242434, #151d20);
}

.wrapping-title {
  white-space: normal;
}


.light-underline {
  border-bottom: 1px solid lightgray;
}

.test-nr-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ion-label {
    font-size: large;
  }
}

.no-data-div {
  min-height: 400px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}

.remove-right-whitespace {
  margin-right: 0;
  padding-right: 0;
}

.popover-viewport.sc-ion-popover-md {
  overflow-y: auto;
  @include custom-scrollbar;
}

.ketos-modal-header {
  ion-title{
    text-align: center;
    color: #FFFFFF;
  }

  ion-toolbar {
    --background: linear-gradient(239deg, #242434, #151d20);
    --color: #00b2ff;
  }

  ion-button {
    color: white;
  }
}
.schedule-modal-content {
  --background: linear-gradient(239deg, #242434, #151d20);
  /*--color: #00b2ff;*/
  --color: white;

  ion-list {
    --background: linear-gradient(239deg, #242434, #151d20);
    /*--color: #00b2ff;*/
    --color: white;
    padding-bottom: 0;
  }

  ion-item{
    --inner-border-width: 0;
    --background: transparent;
    --show-full-highlight: none;
    --border-width: 0;
  }
}
.ketos-modal-footer {
  ion-toolbar {
    --background: linear-gradient(239deg, #242434, #151d20);
    --color: #00b2ff;
  }
  ion-button {
    color: white;
  }
}


.ketos_table_button {
  width: 13px;
  height: 13px;
  display: inline-block;
  //margin: 0 5px;
  cursor: pointer;

  &.ketos_table_button_trash {
    margin-left: 1px;
    background: {
      size: 9px 12px;
      color: transparent;
      position: center right;
      image: url('/assets/shared/images/trash.svg');
      repeat: no-repeat;
    }
  }

  &.ketos_table_button_add {
    -webkit-mask: url('/assets/shared/images/add.svg') no-repeat;
    background-color: #0e8ed9;
    vertical-align: text-top;
  }

  &.ketos_table_button_marker {
    margin-left: 4px;
    background: {
      size: 12px;
      color: transparent;
      position: center right;
      image: url('/assets/shared/images/marker.svg');
      repeat: no-repeat;
    }
  }
}

ion-input {
  border-bottom: 1px solid #00b2ff;
}

.underline {
  border-bottom: 1px solid #00b2ff;
}

.underline-thick {
  border-bottom: 2px solid #00b2ff;
}

.no-underline {
  border-bottom: none;
}

.no-underline-forced {
  border-bottom: none!important;
}

.has-focus.sc-ion-input-md-h {
  border-bottom: 1px solid #00b2ff;
}

ion-toggle {
  padding: 3px;
  overflow-y: visible;
}

.blue-scrollbar {
  @include custom-scrollbar;
}

.topline {
  border-top: 1px solid #00b2ff;
}

.bottomline {
  border-bottom: 1px solid #00b2ff;
}

.dark-toolbar {
  --background: linear-gradient(239deg, #242434, #151d20);
  --color: #00b2ff;
}

.grouped-popover {
  --width: 500px;
  --height: 450px;
}

.user-modal{
  //--min-height: 500px;
  --width: 600px;
}

.dropdown-standard-icon {
  //color: #00b2ff;
  color: #FFFFFF;
  margin-left: 4px;
  --ionicon-stroke-width: 16px;
}

:root {
  --background-gradient: linear-gradient(239deg, #242434, #151d20);
  --hover-gradient: linear-gradient(239deg, #303045, #222E33);
}

.hover-gradient:hover {
  background: var(--hover-gradient);
}

.chevron-icon {
  float: right;
  padding-bottom: 2px;
  padding-right: 2px;
  margin-left: 10px;

  svg {
    fill: #00b2ff;
    height: 7px;
    margin-top: 3px;
    padding: 0;
  }
}

.inactive {
  opacity: 0.5;
  color: red;
}

.opacity {
  input {
    cursor: not-allowed;
    pointer-events: none;
  }

  button {
    cursor: not-allowed;
    pointer-events: none;
  }

  div {
    cursor: not-allowed;
    pointer-events: none;
  }

  span {
    cursor: not-allowed;
    pointer-events: none;
  }

  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}

lib-select-popover {
  height: 100%;
}


// ion-select popover fixes
.popover-viewport {
  background: linear-gradient(239deg, #242434, #151d20);
  height: 100%;
  ion-item.item-focused{
    --background: #1c1c29;
    --color: white;
    --border-color: black;
  }
}

.item-radio-checked.sc-ion-select-popover-md, .item-radio-checked.sc-ion-select-popover-ios {
  --color: #00b2ff;
  ion-label{
    --color: #00b2ff;
  }
}

.fc-timeline-slot-frame, .fc-datagrid-header, .fc-scroller  {
  --color: #00b2ff;
  background: linear-gradient(239deg, #242434, #151d20);
}
  .kt-alerts-search .input-clear-icon.sc-ion-input-md ion-icon.sc-ion-input-md{
  color:#ffffff;
}
.fc-datagrid-cell-main{
  color: #ffffff;
}
.dark-popover {
  .alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
  }
}

.insights-options-popover{
  left: -1%;
}

ion-popover.insights-options-popover {
  &::part(content) {
    min-width: 270px;
  }
}

@media (max-width: 1600px) {
  .insights-options-popover{
    left: -2%;
  }
}

.fc-day-sun, .fc-col-header-cell:not(.fc-day) {
  border-left: 10px solid #878685 !important;
}

.fc-event {
  border-width: 5px;
}

.wrap-ion-icon {
  cursor:pointer;
  ion-icon {
    pointer-events: none;
  }
}
