/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

@import 'fonts';
@import 'sizes';
@import 'colors';
@import 'spacing';
@import 'global';
@import 'mixins';
//@import "~bootstrap/dist/css/bootstrap.css"; 

// These can be extended and applied to any custom element
// Use this and only this so as to not step out of the specifications

// Placeholders
/*[placeholder] {
  @include placeholder(map-get($colors, standard-white), center, map-get($sizes, font-primary));
}*/

// Buttons
.extendButton {
  padding: map-get($sizes, btn-padding);
  border: 0;
  border-radius: 4px;
  background-color: map-get($colors, ketos-blue);
  color: map-get($colors, standard-white);
  font-size: map-get($sizes, btn-font-size);
  font-family: $font-light;
  line-height: map-get($sizes, btn-line-height);
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    filter: grayscale(100%);
  }
}

// Inputs
.extendInput {
  width: 100%;
  border: 0;
  background: transparent;
  color: map-get($colors, standard-white);
  font-size: map-get($sizes, input-font-size);
  font-family: $font-light;
  line-height: map-get($sizes, input-line-height);
}

// Anchors
.extendAnchor {
  display: block;
  color: map-get($colors, standard-white);
  text-decoration: underline;
  font-size: map-get($sizes, anchor-font-size);
  font-family: $font-light;
}

.forcastContainer{
      .mat-tab-group {
          margin-bottom: 48px;
          .mat-tab-header{
            border: none;
            margin-bottom: 2px;
              .mat-tab-label-container{
                  .mat-tab-label{
                    &.mat-tab-label-active{
                      background-color: #3f51b5;
                    }
                      color: #ffffff;
                      min-width: 60px;
                      border: 1px solid #ffffff;
                      .mat-tab-label-content{
                        color:#ffffff
                      }
                  }
              }
          }
      }
}

