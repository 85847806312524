/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
body {
  position: unset;
  overflow: unset;
}

// Overriden ionic 5 default: causes the whole app to scroll to top on any opened popover/modal
body.backdrop-no-scroll {
  overflow: unset;
//  position: fixed;
//  padding-right: 15px;
}

//.my-custom-class {
//  overflow:hidden;
//  position: fixed;
//}

@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@font-face { font-family: "Roboto-Regular"; src: url("./assets/fonts/Roboto-Regular.woff"); }

body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  height: 100vh;
  word-wrap: normal;
}

app-root {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
  overflow: auto;
}

* {
  box-sizing: border-box;
}

.opacity {
  input {
    cursor: not-allowed;
    pointer-events: none;
  }

  button {
    cursor: not-allowed;
    pointer-events: none;
  }

  div {
    cursor: not-allowed;
    pointer-events: none;
  }

  span {
    cursor: not-allowed;
    pointer-events: none;
  }

  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}

@import 'scss/patterns';
@import "scss/mixins";
@import "scss/fonts";
@import "scss/preloader";
// @import "scss/form";
@import "scss/popup";

.water-tank {
  display: flex;
  justify-content: center;

  .graph {
    //fill: url(#bg-tank-top);
    //-webkit-mask: url(../images/tank-top.png) no-repeat;
    //background-color: #0E8ED9;
  }

  .tank-bottom {
    position: relative;
    top: -15px;
    height: 240px;
    background: {
      size: 100% 100%;
      image: url('./assets/water-tank/images/tank-water.png');
      repeat: no-repeat;
    }
  }

  text.yaxistextLabels {
    fill: #ffffff;
    opacity: 1;
    font-size: 14px;
    font-weight: 300;
    font-family: $font-light;
    text-anchor: end;
  }

  text.yaxistextLabels.waterTankLabelMax {
    font-family: $font-regular;
    font-weight: bold;
  }

  .horizontalLinesTank {
    stroke: #ffffff;
    stroke-width: 2;
    opacity: 1;
  }

  .waterContentVolumeLabel {
    font-family: $font-regular;
    font-weight: 800;
    font-size: 24px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    fill: #ffffff;
    opacity: 1;
  }

  text.waterTankPercent {
    font-size: 19px;
    font-weight: 800;
    color: #ffffff;
    font-family: $font-regular;
    fill: #ffffff;
    text-anchor: end;
  }
}

// google map stuff

.app-map-tooltip {
  display: flex;
  z-index: 10;
  align-items: flex-start;
  background-color: #000000;
  border-radius: 6px;
  padding: 10px 18px 10px 10px;

  .app-map-tooltip-icon {
    margin-top: 2px;
    margin-right: 10px;
    margin-left: 1px;

    &.app-map-leak {
      flex: 0 0 12px;
      width: 12px;
      height: 12px;
      background: {
        size: contain;
        position: center;
        image: url('./assets/shared/images/tool.svg');
        repeat: no-repeat;
      }
    }
  }

  .app-map-tooltip-title {
    font-family: $font-regular;
    font-weight: 500;
    font-size: 10px;
  }

  .app-map-tooltip-subtitle {
    color: #9b9b9b;
    font-size: 9px;
    margin-top: 2px;
  }
}

.app-map-tooltip-position {
  position: absolute;
  transform: translate(-50%, calc(-100% - 42px));
  //width: 224px;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 6px);
    transform: translate(0, 100%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #000000;
  }
}

@import 'map.scss';

.info-location {
  position: static;
  color: #000000;
  margin-bottom: 12px;
}

.marker-container {
  margin-top: 7px;
  border-radius: 10px;
  background-color: #ffffff;
  color: map-get($map, bgd);
  font-family: RobotoMedium, sans-serif;
  font-size: 10px;

  .item {
    width: 200px;
    display: flex;
    /*justify-content: space-between;*/
    flex-direction: row;
  }

  .label {
    margin-right: 5px;
    white-space: nowrap;
    color: map-get($map, bgd);
    font-family: RobotoLight, sans-serif;
  }

  .value {
    text-align: right;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 6px 0;
    list-style-type: none;
  }
}

// locations component
//popup__buttonsWrap2
.popup__buttonsWrap2 {
  display: flex;

  //button {
  //  font-size: inherit;
  //  outline: none;
  //  padding: 12px 12px;
  //  cursor: pointer;
  //  &.ng-disabled {
  //    cursor: not-allowed;
  //  }
  //}

  .ion-button-theme{
    background-color: #00b2ff;
    color: #FFFFFF;
    border-radius: 5px;
    height: 32px;
    font-size: 16px;
    margin-left: 8px;
  }
  //
  ////popup__buttonsWrap2__okButton
  //.popup-okButton {
  //  border: 1px solid #00b2ff;
  //  margin-right: 20px;
  //}
  //
  ////popup__buttonsWrap2__okButton
  //.popup-cancelButton {
  //  border: 1px solid #00b2ff;
  //}
}

// timeline-graph component
.chart-tooltip {
  z-index: 72;
  position: absolute;
  display: none;
  border-radius: 6px;
  font-size: 12px;
  padding: 10px;
  width: 190px;
  height: auto;
  background-color: #000000;
  color: #ffffff;
  font-family: $font-light;

  .tooltip-chart-row {
    display: flex;
    justify-content: space-between;

    .chart-tooltip-label {
      margin-right: 5px;
    }

    .chart-tooltip-value {
      word-break: break-all;
    }
  }
}

.arrows {
  stroke: none;
  fill: #41414a;
}

.axis {
  fill: none;
  stroke: #ffffff;
}

.x.axis,
.y.axis {
  text {
    font-weight: lighter;
    font-size: 12px;

    fill: #ffffff;
    stroke: none;
  }
}

.circle {
  cursor: pointer;
}

.x.axis {
  text-anchor: middle;
}

.y.axis {
  text-anchor: end;
}

.xlineclass,
.ylineclass {
  stroke: #ffffff;
  opacity: 0.5;
}

.yLegend {
  font-size: 12px;
  font-weight: 300;
  fill: #ffffff;
}

.horizontalLines, .verticalLines {
  opacity: 0.2;
  stroke: #ffffff;
}

.area {
  stroke-width: 0;
}

.svg#area {
  height: 100%;
}

.thresholdLine {
  stroke: red;
  opacity: 1.5;
}

.thresholdInternalLine {
  stroke: orange;
  opacity: 1.5;
  stroke-dashoffset: 3;
}

.popup-map {
  width: 600px;
  min-height: 165px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
}

.map-marker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
  border-radius: 50%;
  min-width: 26px;

  .map-marker {
    font-size: 16px;
    font-weight: bold;
    padding: 4px 2px 2px 2px;
  }
}

// Dragula
//@import "../node_modules/dragula/dist/dragula.min.css";
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}


// ng ionic component additions
ion-select {
  --padding-start: 0;
  border-bottom: 1px solid #00b2ff;
}

td.toggle-row {
  padding-top: 0;
  padding-bottom: 0;

}

ion-toggle {
  padding: 3px;
  //margin: 8px
  overflow: visible;
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
  margin-top: 0;
  margin-bottom: 0;
}

.disable-click {
  pointer-events: none;
}

:root {
  --dark-background: #1c1c29;

  ion-label {
    font-family: $font-regular;
  }

  ion-list {
    color: white;
    background: transparent;
  }

  ion-list-header {
    color: white;
  }
  ion-radio {
    --color: #00b2ff;
    --color-checked: #00b2ff;
  }

  ion-checkbox {
    --background: transparent;
    --background-checked: #00b2ff;
    --border-color: #00b2ff;
    --border-color-checked: #00b2ff;
  }

  ion-row {
    //background: #1c1c29;
    color: white;
  }

  .inner-scroll {
    @include custom-scrollbar;
  }

  // customizing FullCalendar
  --fc-border-color: #aaa;
  --fc-page-bg-color: linear-gradient(239deg, #242434, #151d20);
  //
  //.fc-theme-standard td{
  //  border: 1px solid #666;
  //  //border: 1px solid var(--fc-border-color,#ddd);
  //}
  //
  //// 'Resources' box
  //.fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
  //  border-color: var(--fc-border-color, #ddd);
  //}
  //
  //// Resources table
  //.fc .fc-scrollgrid-section-liquid>td {
  //  border-color: var(--fc-border-color, #ddd);
  //}

  // Hide timeslot lines
  .fc .fc-timegrid-slot-minor {
    border-top-style: none;
  }
  .fc .fc-timeline-slot-minor {
    border-style: none;
  }

  .fc-theme-standard .fc-list-day-cushion {
    background: linear-gradient(239deg, #242434, #151d20);
  }

  .fc-event-title {
    text-overflow: ellipsis;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  .fc-event.fc-event-draggable {
    border-radius: 10px;
    border-width: 2px;
  }

  .fc-event {
    border-width: 3px;
  }

  .fc .fc-list-event:hover {
    color: black;
  }

  .fc .fc-timegrid-slot {
    height: 1em;
    //height: 10px;
  }

  .fc .fc-slot-past {
    //opacity: 80%;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .fc-scroller-liquid-absolute, .fc-scroller  {
    @include custom-scrollbar();
  }

  .fc-day-past {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .fc-slot-today.fc-slot-past {
    background-color: #656580;
  }
  --fc-today-bg-color: #656580;

  // end customizing FullCalendar
}

ion-select {
  border-bottom: 1px solid #00b2ff;
}

ion-item {
  --background: linear-gradient(239deg, #242434, #151d20);
  --color: white;
}

ion-list {
  --background: linear-gradient(239deg, #242434, #151d20);
  --color: white;
}

.dark-popover {
  --background: linear-gradient(239deg, #242434, #151d20);
  .alert-title.sc-ion-alert-md{
    color: white;
  }
}

.left-open-popover .popover-content {
  left: 50% !important;
}

.wider-popover .popover-content {
  min-width: 350px; //or w/e width
}
.dark-alert{
  --min-width: 320px;
  --width: auto;
  --min-height: 150px;
  --height: auto;
  --max-height: 90%;
  .alert-title.sc-ion-alert-md{
    color: white;
    text-align: center;
  }

  button {
    border-radius: 5px !important;
    width: auto;
    height: auto;

    .alert-button-inner{
      color:  #00b2ff !important;
      justify-content: center;
    }
  }

  .alert-wrapper {
    background: linear-gradient(239deg, #242434, #151d20); // bg color of alert content
  }
}

.alert-dark-background {
  .popover-content{
    background-color: rgba(20, 20, 20, 0.9);
  }
}

.confirm-popup{
  .alert-button-group {
    justify-content: center;
  }
}

.calendar-type-popover {
  --background: linear-gradient(239deg, #242434, #151d20);
  --width: 280px;
}

.schedule-modal {
  --min-width: 50%;
  --min-height: 50%;
}

.fixed-modal {
  position: fixed;
}

.underline-label {
  padding-top: 5px;
  padding-bottom: 6px;
  border-bottom: 1px solid #00b2ff;
}
.underline-invalid {
  border-color: red;
}

.underline {
  border-bottom: 1px solid #00b2ff;
}

.underline-thick {
  border-bottom: 2px solid #00b2ff;
}

ion-button {
  --background: #00b2ff;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios
{
  white-space: normal;
}

.alert-message.sc-ion-alert-md,
.alert-message.sc-ion-alert-ios
{
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 25px;
}

.alert-button-inner.sc-ion-alert-md,
.alert-button-inner.sc-ion-alert-ios
{
  color: #00b2ff;
  justify-content: center;
  //margin-top: 17px;
}

ion-toggle {
  --background: #000;
  --background-checked: #0082cf;

  --handle-background: #fff;
  --handle-background-checked: #00b2ff;
}

@media (max-width: 820px) {
  .web-fab {
    display: none!important;
  }
}

@media (min-width: 820px) {
  .mobile-fab {
    display: none!important;
  }
}


.internal-threshold-line {
  stroke-dasharray: 3,3;
  stroke-dashoffset: 3;
}
.external-threshold-line {
  stroke-dasharray: 3,3;
  //stroke-dashoffset: 2;
}

.select-border{
  border-bottom: 2px solid #00b2ff;
}

.change-password-modal-class{
  --width: 450px !important;
  --height: 410px !important;
}


.assign-locations-modal{
  --height: 250px;
  --width: 450px;
}

.confirm-assign-locations-modal{
  --width: 450px;
  --max-height: 450px;
}

.start-test-modal{
  --height: 400px;
}

.schedule-test-modal{
  --height: 550px;
}

.custom-report-modal{
  --height: 355px;
  --width: 600px;
}

@media screen and (min-width: 720px) and (max-width: 1520px) {

  .popover-content .popover-viewport ion-list{
    max-height: 210px;
    overflow-y: scroll;
    @include custom-scrollbar();
  }
  }


.alerts-modal{
  --height: 400px;
  --width: 400px;
  --border-radius: 0 !important;
}

.locations-map-modal{
  --width: 600px !important;
  --height: 400px !important;
}

.ion-title{
  text-align: center;
  color: #ffffff;
}
lib-custom-select{
  div.select{
  width:100%;
  div.chevron-icon{
    display:block;
  }
 }
}
.chevron-icon{
  float: right;
  color: #00b2ff;
}

.ion-color-ketos-blue {
  --ion-color-base: var(--ion-color-ketos-blue);
  --ion-color-base-rgb: var(--ion-color-ketos-blue-rgb);
  --ion-color-contrast: var(--ion-color-ketos-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ketos-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-ketos-blue-shade);
  --ion-color-tint: var(--ion-color-ketos-blue-tint);
}

.mat-snack-bar-container{
  background-color: #242434;
  color: #ffffff;
  //border: 1px solid #00b2ff;
  .mat-simple-snackbar-action button{
    color: #00b2ff;
  }
}
ion-modal.sc-ion-modal-md-h,ion-modal.start-test-modal{
  --background: none;
}


// multiline in single selection
.sc-ion-label-md-h {
  white-space: normal !important;
}
// .fc-timeline-slot-frame{
//   height: auto !important;
// }
.fc-timeline-slot.fc-timeline-slot-label.fc-slot {
  font-size: 11px;
  div.fc-timeline-slot-frame{
    justify-content: center;
  }
}
ion-item.item-interactive.ion-valid {
  --highlight-background: transparent !important;
}

ion-input {
  caret-color: #ffffff;
}
