/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

$spacing: (
  generic-title: (
    padding-left: 20px,
    margin-top: 20px,
    margin-bottom: 10px,
  ),
);

@mixin spacing($map-name) {
  $_properties: map-keys(map-get($spacing, $map-name));
  $_values: map-values(map-get($spacing, $map-name));

  @each $_property in $_properties {
    #{$_property}: nth($_values, index($_properties, $_property));
  }
}
