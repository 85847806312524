/*!
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

@mixin placeholder($color, $alignment, $size: '') {
  &::-webkit-input-placeholder {
    color: $color;
    text-align: $alignment;
    @if $size != '' {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    text-align: $alignment;
    @if $size != '' {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    text-align: $alignment;
    @if $size != '' {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    text-align: $alignment;
    @if $size != '' {
      font-size: $size;
    }
  }
}

@mixin font-face($font-family, $file-path, $file-extensions: ('ttf'), $font-weight: 'normal', $font-style:'normal') {
  @font-face {
    font-weight: $font-weight;
    font-style: $font-style;
    font-family: unquote($font-family);
    @each $extension in $file-extensions {
      src: url('#{$file-path}.#{$extension}');
    }
  }
}

@mixin custom-scrollbar() {
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0.3);
  }

  &::-webkit-scrollbar
  {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,.3);
    background-color: #00b2ff;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
}
